import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    auditTime,
    BehaviorSubject,
    combineLatest,
    Observable,
    shareReplay,
    withLatestFrom,
} from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { NgxSliderModule, Options } from 'ngx-slider-v2';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import moment from 'moment';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { CommonModule } from '@angular/common';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
    selector: 'bazis-timeline',
    templateUrl: './timeline.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        IconModule,
        TooltipModule,
        NgxSliderModule,
        ColorDirective,
        CommonModule,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClass() {
        return {
            'bazis-timeline': true,
        };
    }

    @Output()
    timeChange = new EventEmitter();

    @Input()
    trackerTimeLine$: Observable<number[]>;

    basicStep = 150000;

    @Input()
    sliderOptions: Options = {
        floor: 0,
        ceil: 86400000,
        step: this.basicStep,
        //animate: false,
    };

    trackerLabels$;

    chart$;

    showChart = true;

    dateTimeBorders = [0, 0];

    speedMultiplier$ = new BehaviorSubject(1);

    basicTimeInterval = 400;

    isPlaying$ = new BehaviorSubject(false);

    emitTimeInterval;

    timeToEmit = new TemplateObservable(null);

    timeToEmit$ = this.timeToEmit.$.pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    speedMultipliers = [1, 2, 4];

    playing$ = combineLatest([this.speedMultiplier$, this.isPlaying$]).pipe(
        map(([speedMultiplier, isPlaying]) => {
            this.clearInterval();
            if (!isPlaying) {
                return;
            }
            this.emitTimeInterval = setInterval(() => {
                const next = this.timeToEmit._ + this.basicStep;
                if (next >= this.dateTimeBorders[1]) {
                    this.skipNext = true;
                    this.timeToEmit.set(this.dateTimeBorders[1]);
                    this.clearInterval();
                    this.isPlaying$.next(false);
                    return;
                } else {
                    this.timeToEmit.set(next);
                }
            }, this.basicTimeInterval / speedMultiplier);
            return true;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    emitting$ = this.timeToEmit$.pipe(
        tap((timeToEmit) => {
            this.timeChange.emit(timeToEmit);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    diffValue = 0;

    moveTrackerToEnd$;

    updateCurrentTime$;

    skipNext = false;

    constructor() {}

    ngOnInit() {
        this.trackerLabels$ = this.trackerTimeLine$.pipe(
            map((period) => {
                this.dateTimeBorders = [period[0], period[1]];
                this.diffValue = (period[1] - period[0]) / 100;

                this.sliderOptions = {
                    ...this.sliderOptions,
                    floor: this.dateTimeBorders[0],
                    ceil: this.dateTimeBorders[1],
                };

                const stepSize = (period[1] - period[0]) / 12;
                const labels = [period[0]];

                while (Math.round(labels[labels.length - 1] + stepSize - period[1]) <= 0) {
                    labels.push(labels[labels.length - 1] + stepSize);
                }
                return labels.map((label) => moment(label).format('HH:mm'));
            }),

            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        this.updateCurrentTime$ = this.trackerLabels$.pipe(
            withLatestFrom(this.isPlaying$),
            tap(([r, isPlaying]) => {
                if (!this.timeToEmit._ || this.timeToEmit._ <= this.dateTimeBorders[0]) {
                    this.timeToEmit.set(this.dateTimeBorders[1]);
                } else {
                    if (
                        !isPlaying &&
                        (this.dateTimeBorders[1] - this.timeToEmit._) /
                            (this.dateTimeBorders[1] - this.dateTimeBorders[0]) <
                            0.05
                    ) {
                        this.skipNext = true;
                        this.timeToEmit.set(this.dateTimeBorders[1]);
                    }
                }
            }),
            debounceTime(0),
            tap((v) => {
                this.skipNext = false;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    setSpeedMultiplier(value) {
        this.speedMultiplier$.next(value);
    }

    clearInterval() {
        if (this.emitTimeInterval) {
            clearInterval(this.emitTimeInterval);
            this.emitTimeInterval = null;
        }
    }

    ngOnDestroy(): void {
        this.clearInterval();
    }

    setPlaying(play) {
        if (play && this.timeToEmit._ >= this.dateTimeBorders[1]) {
            this.timeToEmit.set(this.dateTimeBorders[0]);
        }
        this.isPlaying$.next(play);
    }

    setValueFromSlider(value, isPlaying = false, speedMultiplier = 0) {
        if (this.skipNext) return;
        if (!isPlaying) {
            this.timeToEmit.set(value);
            return;
        }
        if (Math.abs(value - this.timeToEmit._) > this.diffValue) {
            this.timeToEmit.set(value);
            this.setPlaying(false);
        }
    }
}
