<label
    *ngIf="!isEmptyLabel && title"
    part="native"
    class="bazis-label__native"
>
    <slot name="labelStart"></slot>
    {{ title }}
    <span
        *ngIf="required"
        class="bazis-label__required"
    >
        *
    </span>
    <slot name="labelEnd"></slot>
</label>
<label
    *ngIf="isEmptyLabel"
    part="native"
    class="bazis-label__native"
    >&nbsp;</label
>
