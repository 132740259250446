import { Injectable } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { combineLatest, of, shareReplay, Subject, switchMap } from 'rxjs';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { buildFilterStr } from '@bazis/utils';
import { API_DATETIME_FORMAT_Z, SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { catchError, filter, map, startWith, tap } from 'rxjs/operators';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class BazisAnnouncementService {
    isLoading = new TemplateObservable(true);

    readAnnouncementNumber$: Subject<number> = new Subject();

    announcements$ = this.entityService
        .getEntityList$('notifications.announcement', {
            params: {
                filter: buildFilterStr({
                    dt_before_active__gte: moment.utc().format(API_DATETIME_FORMAT_Z),
                }),
            },
            limit: 1000,
        })
        .pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    topAnnouncements$ = combineLatest([this.authService.user$, this.announcements$]).pipe(
        map(([user, announcements]) => {
            if (!user || !announcements.list) return [];
            return announcements.list
                .filter((v) => v.$snapshot.number > user.$snapshot.announcement_number)
                .sort((a, b) => b.$snapshot.number - a.$snapshot.number);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    topAnnouncement$ = this.topAnnouncements$.pipe(
        map((announcements) => (announcements.length ? announcements[0] : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    readAnnouncement$ = combineLatest([this.readAnnouncementNumber$, this.authService.user$]).pipe(
        filter(([readAnnouncementNumber, user]) => readAnnouncementNumber > 0),
        switchMap(([readAnnouncementNumber, user]) => {
            return this.entityService
                .updateEntity$(this.authService.userEntityType, user.id, {
                    announcement_number: readAnnouncementNumber,
                })
                .pipe(
                    tap((v) => {
                        this.readAnnouncementNumber$.next(0);
                    }),
                    switchMap((v) =>
                        this.entityService.getEntity$(this.authService.userEntityType, user.id, {
                            forceLoad: true,
                        }),
                    ),
                    catchError((e) => of(null)),
                );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected srv: BazisSrvService,
        protected entityService: BazisEntityService,
        protected authService: BazisAuthService,
    ) {
        this.readAnnouncement$.subscribe();
        this.announcements$.subscribe();
    }

    readAnnouncement(announcementNumber: number) {
        this.readAnnouncementNumber$.next(announcementNumber);
    }
}
