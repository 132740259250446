<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            playing: playing$ | async,
            speedMultiplier: speedMultiplier$ | async,
            timeToEmitTemp: timeToEmit.$ | async,
            timeToEmit: timeToEmit$ | async,
            moveTrackerToEnd: moveTrackerToEnd$ | async,
            emitting: emitting$ | async,
            updateCurrentTime: updateCurrentTime$ | async,
            isPlaying: isPlaying$ | async
        } as data"
    >
        <!-- text inside btn should be used in tooltip -->
        <div #btnPlay>
            <bazis-button
                fill="clear"
                color="action"
                size="small"
                class="bazis-timeline__play"
                (click)="setPlaying(!data.isPlaying)"
            >
                <bazis-icon
                    slot="icon-only"
                    [name]="data.isPlaying ? 'pause-solid' : 'play-solid'"
                ></bazis-icon>
            </bazis-button>
            <bazis-tooltip
                class="bh-popper-z-index"
                [reference]="btnPlay"
                [isStatic]="true"
                placement="top-start"
            >
                {{
                    t(
                        data.isPlaying
                            ? 'setupTelemetry.app.map.pauseTooltip'
                            : 'setupTelemetry.app.map.watchTooltip'
                    )
                }}
            </bazis-tooltip>
        </div>

        <ng-container *ngFor="let speedMultiplier of speedMultipliers">
            <div #btnSpeed>
                <bazis-button
                    fill="outline"
                    color="action"
                    [active]="speedMultiplier === data.speedMultiplier"
                    size="small"
                    class="bazis-timeline__speed"
                    (click)="speedMultiplier$.next(speedMultiplier)"
                >
                    {{ t('title.timelineSpeed', { number: speedMultiplier }) }}
                </bazis-button>
                <bazis-tooltip
                    class="bh-popper-z-index"
                    [reference]="btnSpeed"
                    [isStatic]="true"
                    placement="top"
                >
                    {{ t('setupTelemetry.app.map.speedTooltip', { number: speedMultiplier }) }}
                </bazis-tooltip>
            </div>
        </ng-container>

        <div class="bazis-timeline__slider-wrapper">
            <div
                [hidden]="!showChart"
                style="width: 100%"
            >
                <ng-content></ng-content>
            </div>

            <!-- https://angular-slider.github.io/ngx-slider/home -->
            <ngx-slider
                *ngIf="data.timeToEmit"
                [value]="data.timeToEmit"
                (valueChange)="setValueFromSlider($event, data.isPlaying, data.speedMultiplier)"
                [options]="sliderOptions"
            ></ngx-slider>

            <div class="bazis-timeline__labels">
                <span *ngFor="let label of trackerLabels$ | async">
                    {{ label }}
                </span>
            </div>
        </div>

        <!-- text inside btn should be used in tooltip -->
        <div #btnChart>
            <bazis-button
                fill="clear"
                color="action"
                size="small"
                (click)="showChart = !showChart"
            >
                <bazis-icon
                    slot="icon-only"
                    name="line-width"
                ></bazis-icon>
            </bazis-button>
            <bazis-tooltip
                class="bh-popper-z-index"
                [reference]="btnChart"
                [isStatic]="true"
                placement="top-end"
            >
                {{
                    t(
                        showChart
                            ? 'setupTelemetry.app.map.hideChart'
                            : 'setupTelemetry.app.map.showChart'
                    )
                }}
            </bazis-tooltip>
        </div>
    </ng-container>
</ng-container>
