import { ViewEncapsulation, Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'bazis-label',
    templateUrl: './label.component.html',
    styleUrls: ['label.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class LabelElement {
    @Input() isEmptyLabel = false;

    @Input() isMajor = false;

    @Input() required = null;

    @Input() title: string = null;

    @Input() isInner: boolean;

    @HostBinding('class') get labelStyle() {
        return {
            'bazis-label': true,
            'bazis-label_major': !!this.isMajor,
            'bazis-label_inner': !!this.isInner,
        };
    }
}
